import React from 'react';
import NavOverlay from '../components/NavOverlay'

export default ()=>
  (
   <div>
     <NavOverlay />
     <div className ='container__content'>
         <h1>gonna make a new portfolio page</h1>
         <p> lorem ipsum etc </p>
         <p> lorem ipsum etc </p>
         <p> lorem ipsum etc </p>
         <p> lorem ipsum etc </p>
     </div>
   </div>
   )
